import setAuthorizationToken from '../../utils/setAuthorizationToken';
let initialState = { logged: false, updating: false };

if (localStorage[process.env.REACT_APP_LOCAL_STORAGE_KEY]) {
  initialState = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_KEY));
  setAuthorizationToken(initialState.token);
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      state = {
        ...state,
        token: action.token,
        user: action.data,
        logged: true,
      };
      localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_KEY, JSON.stringify(state));
      return state;
    case 'LOGOUT':
      state = {
        logged: false,
      };
      localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_KEY);
      return state;
    case 'USER':
      state = {
        ...state,
        user: action.data,
        updating: false,
      };
      localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_KEY, JSON.stringify(state));
      return state;
    default:
      return state;
  }
};

export default authReducer;