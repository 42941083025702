import authReducer from './Auth';
import loadingReducer from './Loading';
import { combineReducers, createStore } from 'redux';

const rootReducers = combineReducers({
  auth: authReducer,
  loading: loadingReducer,
});

const store = createStore(rootReducers);

export default store;