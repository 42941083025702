/* ACTION TYPES */
export const LOADING = 'LOADING';
export const LOADED = 'LOADED';

/* ACTION CREATORS */
export const showLoading = (text, backdrop = 'white') => ({
  type: LOADING,
  text,
  backdrop,
});
export const hideLoading = () => ({type: LOADED});
