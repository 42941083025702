import {LOADING, LOADED} from './actions';

const loadingReducer = (
  state = {visible: false, text: 'Loading...', backdrop: 'white'},
  action,
) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        backdrop: action.backdrop || 'white',
        text: action.text || 'Loading...',
        visible: true,
      };
    case LOADED:
      return {
        ...state,
        backdrop: 'white',
        text: 'Loading...',
        visible: false,
      };
    default:
      return state;
  }
};

export default loadingReducer;
