import React from 'react';
import { connect } from 'react-redux';

const year = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className=" text-center py-3">
      <hr />
      <ul className="list-inline">
        <li className="list-inline-item">
          {year} © <a href={`https://carbon-base.com?utm_source=mdr-web-panel&utm_medium=mdr-web-footer&utm_campaign=mdr-web-footer`} className="carbonbase-ref" target="_blank" rel="noopener noreferrer">
            Carbonbase LLC
          </a>
        </li>
        {process.env.REACT_APP_SLACK_URL && <li className="list-inline-item">- <a href={process.env.REACT_APP_SLACK_URL} target="_blank" rel="noopener noreferrer">Join our Slack community</a></li>}
      </ul>
      <small>{process.env.REACT_APP_VERSION && <span>Version {process.env.REACT_APP_VERSION}</span>}<span> &mdash; Connected to: {localStorage.getItem('@api_url')}</span></small>
    </footer>
  );
};

const mapStateToProps = (state) => ({ brand: state?.auth?.user?.brand });

export default connect(mapStateToProps)(Footer);
