/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert2';
import { IconHome, IconLogout2, IconPlus } from '@tabler/icons-react';

const NavBar = (props) => {
    const navigate = useNavigate();
    const [path, setPath] = useState('');

    useEffect(() => {
        const routeArr = window.location.pathname.split('/');
        if (routeArr[1]) {
            if (routeArr[1] === 'scan') { setPath('Escaneo'); }
            if (routeArr[1] === 'nueva-repo') { setPath('Nueva reposición'); }
            if (routeArr[1] === 'transfers') { setPath('Transferencias'); }
        } else {
            setPath('Reposiciones');
        }
    }, [window.location.pathname]);

    const signout = () => {
        swal.fire({
            title: "Logout?",
            text: "Are you sure you want to logout?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: 'Salir',
            cancelButtonText: 'Quedarse',
            reverseButtons: true,
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-danger text-white mx-2",
                cancelButton: "btn btn-info mx-2",
            }
        }).then((value) => { if (value.isConfirmed) { props.logout(); } });
    };

    return (
        <div className="navbar navbar-expand-lg navbar-light bg-white border-bottom">
            <div className="container">
                <button onClick={window.location.pathname === '/' ? signout : () => navigate('/')} className=" btn btn-lg bg-white btn-default">
                    {window.location.pathname === '/' ? <IconLogout2 size={36} color="black" /> : <IconHome size={36} color='black' />}
                </button>
                <h1 className='text-black text-center'>{path}</h1>
                {window.location.pathname === '/' ?
                    <button onClick={() => navigate('nueva-repo')} className="btn btn-lg btn-default bg-white">
                        <div
                            className='btn btn-lg btn-default bg-primary d-flex align-items-center justify-content-center'
                            style={{ width: 40, height: 40, padding: 0, borderRadius: '50%'}}>
                            <IconPlus size={36} color="white" />
                        </div>
                    </button>
                    :
                    <button disabled={true} className="btn bg-white btn-lg btn-default">
                        <IconPlus size={36} color="white" />
                    </button>
                }
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch({ type: 'LOGOUT' })
});

export default connect(null, mapDispatchToProps)(NavBar);