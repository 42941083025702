import './styles/main.scss';
// import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import React, { useEffect, useState, Suspense, lazy } from 'react';
import {
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { Provider } from 'react-redux';
/* STORE */
import Store from './reducers';
import AppContainer from './components/AppContainer';
/* COMPONENTS */
const Transfers = lazy(() => import('./components/Transfers'));
const Loader = lazy(() => import('./components/Loader'));
const Login = lazy(() => import('./components/SignIn'));
const Error404 = lazy(() => import('./components/Error404'));
const Home = lazy(() => import('./components/Home'));
const Results = lazy(() => import('./components/Results'));
const StartRepo = lazy(() => import('./components/StartRepo'));
const StartRepoInstructions = lazy(() => import('./components/StartRepoInstructions'));
const Scan = lazy(() => import('./components/Scan'));

const App = () => {
  const [auth, setAuth] = useState(Store.getState().auth.logged);
  const [redirect] = useState(window.location.pathname + window.location.search);

  useEffect(() => {
    const login = Store.subscribe(() => {
      setAuth(Store.getState().auth.logged);
    });

    return function cleanup() {
      login();
    };
  }, []);

  return (
    <Provider store={Store}>
      <Loader />
      {
        auth
          ?
          (
            <AppContainer>
              <Suspense fallback={<div className="loader"><div className="typing_loader"></div></div>}>
                <Routes>
                  <Route path='/' element={<Home />} />
                  <Route path='/nueva-repo' element={<StartRepo />} />
                  <Route path='/nueva-repo/instructions' element={<StartRepoInstructions />} />
                  <Route path='/scan/:id' element={<Scan />} />
                  <Route path='/scan/:id/results' element={<Results />} />
                  <Route path='/transfers/:id' element={<Transfers />} />
                  <Route path='/login' element={<Login />} />
                  <Route path='*' element={<Error404 />} />
                </Routes>
              </Suspense>
            </AppContainer>
          )
          :
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/*' element={<Navigate to={`/login${(redirect && redirect.indexOf('login') === -1 && redirect !== '/') ? `?path=${redirect}` : ''}`} />} />
          </Routes>
      }
    </Provider >
  );
};

export default App;
